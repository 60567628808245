import { Box } from "../../styles/global";

export const AboutPage = () => {
  return (
    <Box>
      <div className="main_content">
        <div className="side_image">{/* <figure>aaaaaaaaaaa</figure> */}</div>
        <h1>Quem somos</h1>

        <section className="main_text">
          <p>
            A Agrac, ASSOCIAÇÃO GRANDES CORAÇÕES, é a expressão do amor em ação,
            um esforço conjunto da Associação Grandes Corações para impactar
            vidas e fazer a diferença em nossa sociedade.
          </p>
          <p>
            Inspirados pelos preceitos cristãos e guiados pela sabedoria
            bíblica, estamos determinados a estender as mãos com compaixão,
            generosidade e compromisso.
          </p>
        </section>
      </div>
    </Box>
  );
};

import { Link } from "react-router-dom";
import { Box } from "../../styles/global";
import { Banner, HomeDiv } from "./styles";

export const HomePage = () => {
  return (
    <>
      <Banner>
        <div>
          <h1>Agrac, acolhemos com o coração</h1>
          <p>
            Não importa o que passou o que importa é o que fará com a chance que
            você tem hoje
          </p>

          <Link to={"/doação"}>Doe</Link>
        </div>

        <figure>
          <img
            src={require("../../assets/coracao.jpeg")}
            alt="Regresso com sua familia"
          />
        </figure>
      </Banner>

      <Box>
        <HomeDiv>
          <h1>Agrac</h1>
          <p>
            Não importa o que passou o que importa é o que fará com a chance que
            você tem hoje
          </p>
          <p>Agrac, acolhemos com nosso coração</p>

          <p>
            Saiba como ajudar a AGRAC e os projetos sociais da Organização
            através de diversas maneiras, para continuarmos atuando na promoção
            e desenvolvimento das pessoas em situação de vulnerabilidade social
          </p>
        </HomeDiv>
      </Box>
    </>
  );
};

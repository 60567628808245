import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

:root{
  --color-red-1: #ff000099;
}



body{
  font-family: 'Inter', sans-serif;
}

main{
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

h1 {
    font-size: 35px;
    font-weight: bold;
  }

  p{
    line-height: 20px;
    font-size: 18px;
    font-weight: 400;
  }

  img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  margin: 0 auto;
  width: 1200px;

  min-height: 100%;
`;

import React from "react";
import { Box } from "../../styles/global";

export const ContactPage = () => {
  return (
    <Box>
      <h1>Doações</h1>

      <div>
        <p>Pix cnpj: 424.805.988-61</p>
        <h2>QR code</h2>
        <figure>
          <img src="" alt="" />
        </figure>
      </div>

      <figure>
        <img src="" alt="" />
        <figcaption>Gif</figcaption>
      </figure>

      <div>
        <h1>Doe de diferentes formas</h1>

        <p>
          Aceitamos outros tipos de doações, roupas, sapatos,mobilia
          alimentos...
        </p>
        <p>
          Endereço de nossa caixa postal: Posto de recebimento de Ribeirão Preto
        </p>

        <p>Agradecemos com a sua contribuição</p>
      </div>

      {/* <figure>
        <img src="" alt="" />
        <figcaption>Foto do padre que consegue doações</figcaption>
      </figure> */}
    </Box>
  );
};

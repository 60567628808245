import styled from "styled-components";

export const Banner = styled.div`
  background-color: var(--color-red-1);

  display: flex;
  align-items: center;
  justify-content: space-around;

  div {
    width: 750px;
    height: 150px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    a {
      text-align: center;
      text-decoration: none;

      border: 2px solid;
      padding: 5px;

      width: 40px;
    }
  }
  figure {
    width: 600px;

    img {
      width: 100%;
    }
  }
`;

export const HomeDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 100%;
  width: 100%;
  padding: 20px;
`;

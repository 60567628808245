import { Routes, Route } from "react-router-dom";
import { AboutPage } from "../pages/AboutPage";
import { MissionPage } from "../pages/Mission-page";
import { ContactPage } from "../pages/ContactPage";
import { SocialActionPage } from "../pages/SocialActionPage";
import { HomePage } from "../pages/HomePage";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/Sobre" element={<AboutPage />} />
      <Route path="/Missão" element={<MissionPage />} />
      <Route path="/Social" element={<SocialActionPage />} />
      <Route path="/doação" element={<ContactPage />} />
    </Routes>
  );
};

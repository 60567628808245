import styled from "styled-components";

export const SectionStyle = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 20px;

  div {
    max-width: 60%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 10px;

    span {
      color: red;
      opacity: 0.9;
    }
  }
  figure {
    max-height: 300px;
    max-width: 400px;
  }
`;

export const SectionListStyle = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  div {
    display: flex;
    flex-flow: column wrap;
    gap: 30px;
    max-width: 100%;
    max-height: 400px;

    p {
      max-width: 35%;
      span {
        font-weight: 500;
      }
    }
  }
`;

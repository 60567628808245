import { Box } from "../../styles/global";
import { SectionListStyle, SectionStyle } from "./styles";

export const MissionPage = () => {
  return (
    <Box>
      <SectionStyle>
        <div>
          <h1>Nossa missão</h1>
          <p>
            Nossa missão é abraçar as necessidades daqueles que enfrentam
            dificuldades e adversidades, trazendo alívio, esperança e
            transformação através de ações concretas. O Projeto Agrac nasceu da
            crença de que podemos ser instrumentos de Deus para espalhar o amor
            e ajudar a restaurar a dignidade daqueles que estão em situações
            vulneráveis.{" "}
          </p>
          <span>LUTAR PELO RESPEITO, O DIREITO E A DIGNIDADE.</span>
        </div>

        <figure>
          <img
            src={require("../../assets/foto_grupo_santa.jpeg")}
            alt="Regresso com sua familia"
          />
        </figure>
      </SectionStyle>

      <SectionListStyle>
        <h1>Objetivos</h1>

        <h2>Um novo começo</h2>

        <div>
          <p>
            <span>
              Acolhimento e Cuidado preparar para o futuro, recomeçar
              reintegrar, ressocializar:
            </span>{" "}
            Com base na parábola do Bom Samaritano, buscamos acolher e cuidar
            dos feridos da vida, oferecendo auxílio material, emocional e
            espiritual.
          </p>

          <p>
            <span>Alimentação e Nutrição: </span> Seguindo o exemplo de Jesus
            multiplicando pães e peixes, nos empenhamos em fornecer alimentos
            para os famintos e nutrir tanto o corpo quanto a alma daqueles que
            servimos. Treinamento para aproveitamento de alimentos para famílias
            necessitadas, lembrando que "bem-aventurados os misericordiosos"
            (Mateus 5:7).
          </p>

          <p>
            <span>Educação e Capacitação: </span>Conscientes da importância de
            ensinar a pescar ao invés de dar o peixe, promovemos cursos,
            treinamentos e orientações que empoderam as pessoas para uma vida
            mais digna e produtiva.
          </p>

          <p>
            <span>Advocacia e Justiça: </span> Inspirados por Isaías 1:17,
            defendemos os direitos daqueles que não têm voz, lutando contra a
            injustiça e buscando promover mudanças em nossa sociedade.
          </p>
        </div>
      </SectionListStyle>
    </Box>
  );
};

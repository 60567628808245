import "./reset.css";

import { UserProvider } from "./contexts/userContext";
import { AppRoutes } from "./routes/routes";
import "../src/styles/reset.css";
import { GlobalStyle } from "./styles/global";
import { DefaultTemplate } from "./components/template/DefaultTemplate";

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <UserProvider>
        <DefaultTemplate>
          <AppRoutes />
        </DefaultTemplate>
      </UserProvider>
    </div>
  );
}

export default App;

import styled from "styled-components";

export const FooterStyled = styled.footer`
  height: 10vh;

  /* background-color: var(--color-red-1); */

  box-shadow: 0 0 20px 0;

  section {
    display: flex;
    justify-content: space-around;
    align-items: center;

    width: 1200px;
    height: 100%;

    margin: 0 auto;
  }

  div {
    width: 30%;
    height: 100%;
    gap: 10px;

    border-radius: 10px;

    display: flex;
    justify-content: center;
    flex-direction: column;
    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
`;

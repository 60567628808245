import React from "react";
import { Box } from "./styles";

export const SocialActionPage = () => {
  return (
    <Box>
      <h1>Ação Social</h1>

      <div>
        <figure></figure>

        <div>
          <h2>ATIVIDADES E PROJETOS PROPOSTAS:</h2>

          <p>
            Workshops e palestras sobre educação financeira, empregabilidade e
            desenvolvimento pessoal, com base em princípios cristãos.
            Oferecimento de assistência jurídica gratuita para aqueles que não
            podem se defender, lembrando que "o Senhor é defensor dos oprimidos"
            (Salmos 10:14). Ações de solidariedade e visitas a instituições,
            hospitais e presídios, trazendo consolo, esperança e a mensagem de
            que "Deus é nosso refúgio e fortaleza" (Salmos 46:1). NOSSOS
            PRINCIPIOS: "Deem, e será dado a vocês: uma boa medida, calcada,
            sacudida e transbordando, será dada a vocês. Pois a medida que
            usarem também será usada para medir vocês." - Lucas 6:38
          </p>

          <p>
            O Projeto Agrac é mais do que uma iniciativa; é uma resposta ao
            chamado do amor e da compaixão. Com as mãos e corações unidos,
            estamos prontos para transformar vidas e fazer a diferença,
            inspirados pelo exemplo de Jesus Cristo, que nos ensinou a amar e
            servir.
          </p>
        </div>
      </div>
    </Box>
  );
};

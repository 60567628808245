import styled from "styled-components";

export const HeaderStyled = styled.header`
  width: 100%;
  height: 10vh;

  display: flex;
  justify-content: space-around;
  align-items: center;

  box-shadow: 0 0 32px 0;

  div {
    height: 100%;
    width: 30%;
    padding: 20px;

    display: flex;
    align-items: center;

    figure {
      height: 100%;
      max-width: 30%;
    }

    img {
      height: 100%;
    }
  }
  nav {
    width: 40%;
  }
  ul {
    display: flex;
    justify-content: space-around;

    width: 100%;
  }
  a {
    text-decoration: none;
    color: black;
  }
  a:hover {
    text-decoration: underline;
    text-decoration-color: goldenrod;
    text-decoration-thickness: 2px;
  }

  /* @media (max-width: 600px) {
    height: 15vh;
    justify-content: space-between;
    nav {
      display: flex;
      width: 30%;
    }
    ul {
      flex-direction: column;
      gap: 10px;
    }
  } */
`;

import { createContext, useState } from "react";

interface IUserProviderProps {
  page: boolean;
  setPage: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserContext = createContext<IUserProviderProps>(
  {} as IUserProviderProps
);

export const UserProvider = ({ children }: any) => {
  const [page, setPage] = useState(false);

  return (
    <UserContext.Provider value={{ page, setPage }}>
      {children}
    </UserContext.Provider>
  );
};

import { Link } from "react-router-dom";
import { FooterStyled } from "./styles";

export const Footer = () => {
  const userData = {
    whatsappNumber: "+5516992883060",
  };
  return (
    <FooterStyled>
      <section>
        <div className="contacts">
          <h4>Contatos</h4>
          <ul>
            <li>
              <a
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=+55${userData.whatsappNumber}&text=Ol%C3%A1%21%20Cheguei%20at%C3%A9%20voc%C3%AA%20por%20meio%20da%20p%C3%A1gina%20da%20AGRAC%20na%20internet%20e%20estou%20interessado%20em%20aprender%20mais%20sobre%20os%20incr%C3%ADveis%20servi%C3%A7os%20que%20voc%C3%AAs%20oferecem.%20%F0%9F%98%8A`}
                rel="noreferrer"
              >
                Abrir WhatsApp
              </a>
            </li>

            <li>Nosso email: associacaoagraac@gmail.com</li>
          </ul>
        </div>

        <div className="social">
          <h4>Redes Sociais</h4>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/AGRACIMPACTANDOVIDAS"
                rel="noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a href="/Instagram">Instagram</a>
            </li>
          </ul>
        </div>

        <div>
          <h4>
            <Link to={"/doação"}>Doações</Link>
          </h4>
        </div>
      </section>
    </FooterStyled>
  );
};

import { ReactNode } from "react";
import { Header } from "../Header";
import { Footer } from "../footer";
interface iProps {
  children: ReactNode;
}
export const DefaultTemplate = ({ children }: iProps) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};
